var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-loading-dialog',{attrs:{"loading":_vm.$apollo.loading}},[_c('base-page-layout',{attrs:{"title":"Log de Ações"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"mr-9",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.goToDoc}},'v-icon',attrs,false),on),[_vm._v("info")])]}}])},[_c('span',[_vm._v("Ficou com dúvidas sobre a tela? Clique aqui e veja documentação")])])],1)],1)],1),_c('v-col',[_c('mf-card-container',{attrs:{"placeholder":"Busca por motivo ou erro","use-search":"","title":"Listagem dos logs","description":'Listagem com os logs vinculados a conta '},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-data-table',{attrs:{"items":_vm.adminLogs,"loading":_vm.$apollo.queries.adminLogs.loading,"search":_vm.search,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSafe(function () { return item.user.login; }) || item.user_id)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.delta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getKeys(item.delta).length ? _vm.getKeys(item.delta).toString() : '-')+" ")]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.error || '-')+" ")]}},{key:"item.log_message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.log_message || '-')+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.logDetails(item._id)}}},[_c('v-list-item-title',[_vm._v("Ver detalhes")])],1)],1)],1)]}}],null,true)})],1),_c('base-dialog',{attrs:{"title":"Log","max-width":"910"},model:{value:(_vm.logDetail),callback:function ($$v) {_vm.logDetail=$$v},expression:"logDetail"}},[_c('v-card',{attrs:{"flat":""}},[(!_vm.noChanges)?_c('v-alert',{staticClass:"ma-2",attrs:{"dense":"","type":"info","outlined":""}},[_c('strong',[_vm._v("*_OLD")]),_vm._v(": Valor antes da alteração / "),_c('strong',[_vm._v("*_NEW")]),_vm._v(": Valor alterado / "),_c('strong',[_vm._v("*_ADDED")]),_vm._v(": Valor inserido / "),_c('strong',[_vm._v("*_DELETED")]),_vm._v(": Valor excluído")]):_c('v-alert',{staticClass:"ma-4",attrs:{"dense":"","type":"info"}},[_vm._v("Alterações não detectadas.")]),_c('vue-json-pretty',{staticClass:"px-12 py-8",attrs:{"deep":10,"data":_vm.log,"highlight-mouseover-node":"","show-line":""},on:{"update:data":function($event){_vm.log=$event}}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }