<template>
  <mf-loading-dialog :loading="$apollo.loading">
    <base-page-layout title="Log de Ações">
      <v-row no-gutters>
        <v-col cols="12">
          <v-row justify="end">
            <v-col class="mr-9" cols="auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="goToDoc">info</v-icon>
                </template>
                <span>Ficou com dúvidas sobre a tela? Clique aqui e veja documentação</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <mf-card-container
            v-model="search"
            placeholder="Busca por motivo ou erro"
            use-search
            title="Listagem dos logs"
            :description="'Listagem com os logs vinculados a conta '"
          >
            <v-data-table :items="adminLogs" :loading="$apollo.queries.adminLogs.loading" :search="search" :headers="headers">
              <template v-slot:[`item.user_id`]="{ item }">
                {{ getSafe(() => item.user.login) || item.user_id }}
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
              </template>

              <template v-slot:[`item.delta`]="{ item }">
                {{ getKeys(item.delta).length ? getKeys(item.delta).toString() : '-' }}
              </template>

              <template v-slot:[`item.error`]="{ item }">
                {{ item.error || '-' }}
              </template>

              <template v-slot:[`item.log_message`]="{ item }">
                {{ item.log_message || '-' }}
              </template>

              <template v-slot:[`item.details`]="{ item }">
                <v-menu :close-on-content-click="false">
                  <template #activator="{ on }">
                    <v-icon v-on="on">more_vert</v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="logDetails(item._id)">
                      <v-list-item-title>Ver detalhes</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </mf-card-container>

          <!-- changes dialog -->
          <base-dialog v-model="logDetail" title="Log" max-width="910">
            <v-card flat>
              <v-alert v-if="!noChanges" class="ma-2" dense type="info" outlined
                ><strong>*_OLD</strong>: Valor antes da alteração / <strong>*_NEW</strong>: Valor alterado / <strong>*_ADDED</strong>: Valor inserido /
                <strong>*_DELETED</strong>: Valor excluído</v-alert
              >
              <v-alert v-else class="ma-4" dense type="info">Alterações não detectadas.</v-alert>
              <vue-json-pretty class="px-12 py-8" :deep="10" :data.sync="log" highlight-mouseover-node show-line />
            </v-card>
          </base-dialog>
        </v-col>
      </v-row>
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import { QUERY_GET_OPERATION_LOGS } from '@/modules/accounts/graphql'
import BaseDialog from '../../../../components/atomic-components/atoms/BaseDialog.vue'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    VueJsonPretty: () => import('vue-json-pretty'),
    BaseDialog
  },
  data: () => ({
    accountId: '',
    search: '',
    panels: 0,
    log: {},
    logDetail: false,
    noChanges: false
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Email do operador',
          value: 'user_id',
          width: '15%'
        },
        {
          text: 'Data da Alteração',
          value: 'created_at',
          width: '15%'
        },
        {
          text: 'Campos Alterados',
          value: 'delta',
          width: '20%',
          sortable: false
        },
        {
          text: 'Motivo',
          value: 'log_message',
          width: '20%'
        },
        {
          text: 'Erros',
          value: 'error',
          width: '20%'
        },
        {
          text: '',
          value: 'details',
          width: '10%',
          sortable: false
        }
      ]
    }
  },
  apollo: {
    adminLogs: {
      query: QUERY_GET_OPERATION_LOGS,
      fetchPolicy: 'network-only',
      variables() {
        return { id: this.accountId }
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    goToDoc() {
      window.open('https://mercafacil.getoutline.com/doc/logs-xiA2KccoVY', '_blank')
    },
    formatDate(date) {
      return this.$moment(date).format('DD/MM/YYYY, HH:mm:ss')
    },
    getKeys(obj) {
      return Object.keys(obj)
    },
    logDetails(id) {
      const selectedLog = this.adminLogs.filter(log => {
        return log._id === id
      })

      if (!this.getKeys(selectedLog[0].delta).length) {
        this.log = selectedLog[0]
        this.logDetail = true
        this.noChanges = true
        return
      }

      this.log = selectedLog[0].delta
      this.logDetail = true
      this.noChanges = false
    },
    closeLogDetail() {
      this.log = {}
      this.logDetail = false
    }
  }
}
</script>
